<template>
  <div class="index_date">
    <div class="index_date_main">
      <div class="date_main_left">
        <div
          v-for="item in dateorderData"
          @click="getDateorderDetails(item.date)"
          :key="item.date"
          class="date_main_item"
        >
          <div
            class="date_main_item_top"
            :class="item.have == true ? 'bgred' : 'bgblue'"
          >
            <p>{{ item.date }}</p>
            <p>{{ item.week }}</p>
          </div>
          <div class="date_main_item_bottom">
            <span
              :class="dateToTimestamp(item.date) > dateToTimestamp(nowDate)
                  ? 'grayc': ''">
                <span v-if="item.have">{{ item.num-item.unNum }}/{{ item.num }}</span>  
                <span v-else>{{ item.num }}</span>
                  </span>
          </div>
        </div>
      </div>
      <div class="date_main_right">
        <p class="search">
          <el-date-picker
            size="mini"
            v-model="searchForm.date"
            placeholder="请选择月份,默认为本月"
            type="month"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
          <button class="btn1" @click="getData()">
            <i class="el-icon-search"></i>
            查询
          </button>
        </p>

        <p style="margin-bottom: 8px">
          <span class="gray999">当月计划总数</span>
          <span class="mgr">{{ form.total }}</span>
        </p>
        <el-progress
          :text-inside="true"
          :stroke-width="16"
          :percentage="form.比例"
        ></el-progress>
        <div
          style="display: flex; justify-content: space-between; margin-top: 8px"
        >
          <p>
            <span class="gray999">已完成</span>
            <span class="mgr">{{ form.已完成 }}</span>
          </p>
          <p>
            <span class="gray999">未完成</span>
            <span class="mgr">{{ form.未完成 }}</span>
          </p>
        </div>

        <div class="eChart" ref="eChart"></div>
      </div>
    </div>
    <div class="modal" v-show="modal == 1">
      <div class="inner">
        <div class="title">
          <span>
            <i
              class="el-icon-arrow-left"
              @click="addDate(detailnowDate, -1)"
            ></i>
            {{ typeChange(detailnowDate) }}
            <i
              class="el-icon-arrow-right"
              @click="addDate(detailnowDate, 1)"
            ></i>
          </span>
          <span
            @click="
              modal = 0;
              detailForm = {};getData()
            "
            class="pointer"
          >
            <i class="el-icon-circle-close"></i>
          </span>
        </div>
        <div class="mm">
          <el-collapse v-model="activeNames">
            <el-collapse-item
              :title="'安装计划 ' + '    ' + detailForm.azNum"
              name="1"
            >
              <div
                v-for="item in detailForm.安装计划"
                :key="item.id"
                class="collapse_item"
              >
                <div>
                  <p class="jc">{{ item.projectName }}</p>
                  <p>{{ item.areaName }} {{ item.address }}</p>
                  <p>{{ item.linkman }} {{ item.phone }}</p>
                </div>
                <div>
                  <p class="colorgreen" v-if="item.status != 4">已指派</p>
                  <p class="colorred" v-else @click="assign(item, 1,'安装计划')">
                    未指派
                  </p>
                  <p>{{ item.handsPersonName }}</p>
                </div>
              </div>
            </el-collapse-item>

            <el-collapse-item
              :title="'维修计划 ' + '    ' + detailForm.wxNum"
              name="2"
            >
              <div
                v-for="item in detailForm.维修计划"
                :key="item.id"
                class="collapse_item"
              >
                <div>
                  <p class="jc">{{ item.projectName }}</p>
                  <p>{{ item.areaName }} {{ item.address }}</p>
                  <p>{{ item.linkman }} {{ item.phone }}</p>
                </div>
                <div>
                  <p class="colorgreen" v-if="item.status != 4">已指派</p>
                  <p class="colorred" v-else @click="assign(item, 2,'维修计划')">未指派</p>
                  <p>{{ item.handsPersonName }}</p>
                </div>
              </div>
            </el-collapse-item>
            <el-collapse-item
              :title="'运营计划 ' + '    ' + detailForm.yyNum"
              name="3"
            >
              <div
                v-for="item in detailForm.运营计划"
                :key="item.id"
                class="collapse_item"
              >
                <div>
                  <p class="jc">{{ item.projectName }}</p>
                  <p>{{ item.areaName }} {{ item.address }}</p>
                  <p>{{ item.linkman }} {{ item.phone }}</p>
                </div>
                <div>
                  <p class="colorgreen" v-if="item.status != 3">已指派</p>
                  <p class="colorred" v-else @click="assign(item, 3,'运营计划')">未指派</p>
                  <p>{{ item.handsPersonName }}</p>
                </div>
              </div>
            </el-collapse-item>
            <el-collapse-item
              :title="'渠道计划 ' + '    ' + detailForm.qdNum"
              name="4"
            >
              <div
                v-for="item in detailForm.渠道计划"
                :key="item.id"
                class="collapse_item"
              >
                <div>
                  <p class="jc" v-if="item.isMaterial==2">{{ item.merchantName }}（代理商）</p>
                  <p class="jc" v-else>{{ item.projectName }}</p>
                  <p>{{ item.areaName }} {{ item.address }}</p>
                  <p>{{ item.linkman }} {{ item.phone }}</p>
                </div>
                <div>
                  <p class="colorgreen" v-if="item.status != 4">已指派</p>
                  <p class="colorred" v-else @click="assign(item, 4,'渠道计划')">未指派</p>
                  <p>{{ item.handsPersonName }}</p>
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
    </div>
    <div class="indexModal" v-if="indexModal == 1">
      <div class="inner">
        <p class="title">
          <span>指派人员</span>
          <span
            @click="
              indexModal = 0;
              littleForm = {};
            "
            class="pointer"
          >
            <i class="el-icon-circle-close"></i>
          </span>
        </p>
        <div class="modal_item3">
          <div class="input_item">
            <p>项目：</p>
            <span>{{littleForm.projectName}}</span>
          </div>
          <div class="input_item">
            <p>订单类型：</p>
            <span>{{littleForm.s}}</span>
          </div>
          <div class="input_item">
            <p>指派人员：</p>
            <el-select
              v-model="littleForm.handsPersonId"
              size="mini"
              placeholder="请选择"
            >
              <el-option
                v-for="item in option6"
                :key="item.userId"              
                :label="item.nickName"
                :value="item.userId"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="foot">
          <button class="finish" @click="passWordChange">完成</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dateorder, dateorderDetails, orderExamine,getUserByType,distributorNoAdd,operationAdd,maintainAdd } from "@/api/apis.js";
import { getNowTime1 } from "@/utils/getTime.js";
export default {
  data() {
    return {
      searchForm: {},
      dateorderData: [],
      form: {},
      nowDate: "",
      modal: 0,
      detailnowDate: "",
      detailForm: { 安装计划: [], 运营计划: [], 维修计划: [], 渠道计划: [] },
      kg: "  ",
      activeNames: ["1"],
      indexModal:0,
      littleForm:{},
      option6:[],
    };
  },
  methods: {
    getData() {
      let data = { createTime: this.searchForm.date || this.nowDate };
      dateorder(data).then((res) => {
        if (res.code == "200") {
          this.dateorderData = res.data.list;
          this.form = res.data;
          this.form.比例 = (this.form.比例 * 100).toFixed(2) - 0;
          this.getEcharts();
        } else {
          this.dateorderData = [];
          this.form = {};
        }
      });
    },
    getDateorderDetails(d) {
      let data = { createTime: d };
      dateorderDetails(data).then((res) => {
        if (res.code == 200) {
          this.detailnowDate = d;
          this.modal = 1;
          this.detailForm = res.data;
        }
      });
    },
    typeChange(d) {
      let arr = d.split("-");
      return arr[0] + "年" + arr[1] + "月" + arr[2] + "日";
    },
    getEcharts() {
      var mychart = this.$echarts.init(this.$refs.eChart);
      let option = {
        // color: ["#f33c3e", "#f12121", "#e61010", "#d00e0e", "#bb0d0e"],
        tooltip: {
          trigger: "item",
        },
        legend: {
          top: "1%",
          left: "center",
        },
        series: [
          {
            name: "订单类型",
            type: "pie",
            radius: ["30%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: true,
              position: "outside",
              formatter: "{d}%",
              rich: {
                dark: {
                  color: "#666",
                  // padding: [20, 0, 0, 0],
                },
                color: {
                  color: "#fff",
                },
              },
            },
            // emphasis: {
            //     label: {
            //         show: true,
            //         fontSize: '40',
            //         fontWeight: 'bold'
            //     }
            // },
            labelLine: {
              show: false,
            },
            data: [
              { value: this.form.安装订单, name: "安装订单" },
              { value: this.form.渠道订单, name: "渠道订单" },
              { value: this.form.运营订单, name: "运营订单" },
              { value: this.form.维修订单, name: "维修订单" },
              // { value: 300, name: "视频广告" },
            ],
          },
        ],
      };
      mychart.setOption(option);
    },
    dateToTimestamp(dateStr) {
      if (!dateStr) {
        return "";
      }
      let date = new Date(dateStr);
      let timestamp = date.getTime();
      return timestamp;
    },
    addDate(date, days) {
      var d = new Date(date);
      d.setDate(d.getDate() + days);
      var m = d.getMonth() + 1;
      this.detailnowDate = d.getFullYear() + "-" + m + "-" + d.getDate();
      this.getDateorderDetails(this.detailnowDate);
    },
    assign(item, i,s) {
      this.littleForm.projectName=item.projectName
      this.littleForm.id=item.id
      this.littleForm.s=s
      this.littleForm.type=i
      this.indexModal=1
      if(i==3){
            getUserByType({type:24}).then((res) => {
              if (res.code == 200) {
                this.option6 = res.data;
              }
            });
      }else{
        getUserByType({type:23}).then((res) => {
              if (res.code == 200) {
                this.option6 = res.data;
              }
            });
      }
    },
    passWordChange(){
      if(this.littleForm.type==1){
        orderExamine({
          id:this.littleForm.id,
          status: 5,
          handsPersonId: this.littleForm.handsPersonId,
        }).then((res) => {
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: res.info,
            });
            this.getDateorderDetails(this.detailnowDate);
            this.indexModal=0
            this.littleForm={}
          } else {
            this.$message.error(res.data.info);
          }
        });
      }else if(this.littleForm.type==2){
          maintainAdd({id:this.littleForm.id,
          status: 5,
          handsPersonId: this.littleForm.handsPersonId,}).then(res=>{
          if(res.code==200){
            this.$message({
            type: "success",
            message: res.info,
          });
           this.getDateorderDetails(this.detailnowDate);
           this.indexModal=0
            this.littleForm={}
        } else {
          this.$message.error(res.data.info);
        }
      })
      }else if(this.littleForm.type==3){
         operationAdd({id:this.littleForm.id,
          status: 4,
          handsPersonId: this.littleForm.handsPersonId,}).then(res=>{
          if(res.code==200){
            this.$message({
            type: "success",
            message: res.info,
          });
         this.getDateorderDetails(this.detailnowDate);
           this.indexModal=0
            this.littleForm={}
        } else {
          this.$message.error(res.data.info);
        }
      })
      }else if(this.littleForm.type==4){
         distributorNoAdd({id:this.littleForm.id,
          status: 5,
          handsPersonId: this.littleForm.handsPersonId,}).then(res=>{
          if(res.code==200){
            this.$message({
            type: "success",
            message: res.info,
          });
         this.getDateorderDetails(this.detailnowDate);
           this.indexModal=0
            this.littleForm={}
        } else {
          this.$message.error(res.data.info);
        }
      })
      }
    },
  },
  mounted() {
    // this.searchForm.date = getNowTime1();
    this.nowDate = getNowTime1();
    this.getData();
     
  },
};
</script>

<style lang='less'>
.index_date {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 16px 18px;
  box-sizing: border-box;
  display: flex;
  .index_date_main {
    // width: 100%;
    // height: 100%;
    font-size: 14px;
    flex: 1;
    background-color: #fff;
    padding: 32px;
    display: flex;
    .date_main_left {
      display: flex;
      flex-wrap: wrap;
      //  align-items:flex-start;
      align-content: space-between;
      border-right: 1px solid #e7e8ea;
      width: 66.5%;
      .date_main_item {
        width: 12.1%;
        height: 18.2%;
        margin-right: 20px;
        text-align: center;
        border: 1px solid #e7e8ea;
        border-radius: 10px;
        box-shadow: 1px 1px 4px #dfdada;
        cursor: pointer;
        .bgblue {
          background-color: #c3def9;
        }
        .bgred {
          background-color: #d62829;
          color: #fff;
        }
        .date_main_item_top {
          height: 50%;
          border-radius: 10px 10px 0 0;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
        }
        .date_main_item_bottom {
          height: 50%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          font-size: 26px;
          .grayc {
            color: #ccc;
          }
        }
      }
    }
    .date_main_right {
      flex: 1;
      padding-left: 20px;
      .search {
        margin-bottom: 28px;
      }
      .mgr {
        margin-left: 16px;
      }
      .eChart {
        width: 100%;
        height: 500px;
        margin-top: 50px;
        // background-color: rgb(44, 32, 32);
      }
    }
  }
  .modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    display: flex;
    align-items: center;
    .inner {
      width: 1200px;
      height: 790px;
      box-sizing: border-box;
      background-color: white;
      margin: auto;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      border-radius: 10px;
      position: relative;
      .title {
        line-height: 78px;
        font-size: 20px;
        font-weight: 600;
        i {
          cursor: pointer;
        }
        & > span:nth-child(1) {
          margin: 0 460px 0 500px;
          i {
            color: #ffa90c;
          }
        }
      }
      .mm {
        padding: 0 20px;
        max-height: 710px;
        overflow: auto;
        &::-webkit-scrollbar {
          width: 0px;
        }
        .el-collapse-item__header {
          border-bottom: none;
          background-color: #616f7c;
          color: #fff;
          // font-size: 18px;
          font-weight: 600;
          margin-bottom: 20px;
          padding-left: 18px;
        }
        .collapse_item {
          display: flex;
          justify-content: space-between;
          padding: 10px 30px 10px 10px;
          border-bottom: 1px solid #f0ebeb;
          color: #999;
          .jc {
            font-size: 16px;
            color: #000;
          }
          .colorred {
            cursor: pointer;
          }
        }
      }
    }
  }
  .indexModal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    z-index: 1100;
    display: flex;
    align-items: center;
    .inner {
      width: 450px;
      height: 400px;
      box-sizing: border-box;
      background-color: white;
      margin: auto;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      border-radius: 10px;
      box-shadow: 1px 1px 10px #888888;
      position: relative;
      .title {
        color: #2b2e36;
        line-height: 50px;
        font-weight: bold;
        font-size: 12px;
        display: flex;
        justify-content: space-between;
        padding: 0 30px;
        border-bottom: 1px solid #dddddd;
        i {
          font-size: 18px;
          font-weight: 600;
          cursor: pointer;
        }
      }
      .modal_item3 {
        padding: 0 40px;
        margin: 24px 0;
        .input_item {
          display: flex;
          margin-bottom: 13.5px;
          line-height: 28px;
          p {
            width: 80px;
            
          }
          .el-input {
            width: 280px;
          }
        }
      }
      .foot {
        width: 100%;
        position: absolute;
        bottom: 24px;
        .finish {
          display: block;
          width: 350px;
          height: 40px;
          border-radius: 6px;
          background-color: #d62829;
          color: #fff;
          cursor: pointer;
          margin: 0 auto;
        }
        .confirm_delete {
          display: block;
          width: 350px;
          height: 40px;
          border-radius: 6px;
          background-color: #ff0101;
          color: #fff;
          cursor: pointer;
          margin: 0 auto;
        }
      }
    }
  }
}
</style>